<template>
  <v-container>
      <v-card color="grey lighten-5" elevation="0">
        <v-card-title>
          <h3 class="font-weight-black display-0 basil--text">
            ACCION TERAPEUTICA
          </h3>
        </v-card-title>
      </v-card><br>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="listModifyEntities"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
            NUEVO
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            color="success"
            dark
            elevation="0"
          >{{ formTitle }}</v-toolbar><br>
          <v-card-text>
            <v-container>
              <v-row>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    label="NOMBRE O DESCRIPCION"
                    outlined
                    dense
                    :error-messages="errors.get('name')"
                    @keydown="errors.clear('name')"
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success darken-1"
              text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="success darken-1"
              text
              @click="save"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>


      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">¿Esta seguro de eliminar este registro?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="3"
          >
            <v-text-field
              v-model="search"
              clearable
              outlined
              dense
              clear-icon="mdi-delete"
              label="Buscar"
              class="mt-7"
            ></v-text-field>
        </v-col>


      </v-row>
      <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [5, 10, 20, 50] }"
        :server-items-length="totalEntities"
        :options.sync="options"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>

        </template>
        <template v-if="progress" v-slot:no-data>
          <div class="text-center">
            <v-progress-circular
              :size="40"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
      </v-data-table>
      <div class="text-center ma-2">
        <v-snackbar
          v-model="snackbar"
        >
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
</template>
<script>
import axios from "axios";
import debounce from 'lodash/debounce';
import {Errors} from '../../plugins/errors'

export default {
  name: "Entity",
  components: {},
  data () {
    return {
      dialog: false,
      dialogDelete: false,
      progress: false,
      entities: [],
      listEntities: [],
      listEntity: [],
      filterEntity: null,
      listFilterEntity: [],
      listType: [],
      errors: new Errors(),
      snackbar: false,
      filters: {
        search: ''
      },
      text: 'Operación realizada con éxito !!!',
      totalEntities: 0,
      options: {},
      headers: [
        { text: 'NOMBRE',     value: 'name',             sortable: false, class: "success white--text"},
        { text: 'RUC',        value: 'ruc',              sortable: false, class: "success white--text"},
        { text: 'DIRECCIÓN',  value: 'direction',        sortable: false, class: "success white--text"},
        { text: 'TIPO',       value: 'entity_type.name', sortable: false, class: "success white--text"},
        { text: 'DEPENDE DE', value: 'parent.name',      sortable: false, class: "success white--text"},
        { text: 'ACCIONES',   value: 'actions',          sortable: false, class: "success white--text"},
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        ruc: '',
        direction: '',
        entity_id: null,
        entity_type_id: 0,
        parent: {
          id: null
        },
      },
      defaultItem: {
        name: '',
        ruc: '',
        direction: '',
        entity_id: null,
        entity_type_id: 0,
        parent: {
          id: null
        },
      },
      parentModify: {
        id: null,
        name: 'NINGUNO'
      }
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'REGISTRAR ACCION TERAPEUTICA' : 'EDITAR ACCION TERAPEUTICA'
    },
    search: {
      get () {
        return this.filters.search;
      },
      set (value) {
        this.filters.search = value;
        this.filterSearch()
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      async handler () {
        await this.fetchEntities();
      }
    },
  },
  created () {
    this.fetchEntities()
    this.listModifyEntities()
  },
  methods: {
    fetchEntities () {
      const params = { ...this.options, ...this.filters }
      let modifyList = []
      this.progress = true
      axios.get('/api/entities', {params})
      .then(response => {
        modifyList = response.data.data.data
        modifyList.filter((value) => {
          return value.parent == null ? value.parent = this.parentModify : value.parent
        })
        this.entities = modifyList
        this.listEntity = response.data.entity
        this.listFilterEntity = response.data.entity
        this.listType = response.data.type
        this.totalEntities = response.data.data.total
        this.progress = false
      })
    },

    listModifyEntities () {
      axios.get('/api/list/entities')
        .then(response => {
          this.listEntities = response.data.entity
        })
    },

    saveEntities () {
      const createEntity = {
        'name'          : this.editedItem.name,
        'ruc'           : this.editedItem.ruc,
        'direction'     : this.editedItem.direction,
        'parent_id'      : this.editedItem.parent.id,
        'entity_type_id': this.editedItem.entity_type_id,
      }

      axios.post('/api/entities', createEntity)
        .then(() => {
          this.fetchEntities()
          this.close()
          this.snackbar = true;
        },error => this.errors.record(error.response.data.errors));
    },

    updateEntities () {
      const editEntity = {
        'name'          : this.editedItem.name,
        'ruc'           : this.editedItem.ruc,
        'direction'     : this.editedItem.direction,
        'parent_id'      : this.editedItem.parent.id,
        'entity_type_id': this.editedItem.entity_type_id,
        'id': this.editedItem.entity_id
      }

      axios.put(`/api/entities/${this.editedItem.entity_id}`, editEntity)
        .then(() => {
          this.fetchEntities()
          this.close()
          this.snackbar = true;
        },error => this.errors.record(error.response.data.errors));
    },

    filterSearch: debounce(async function () {
      await this.fetchEntities();
    }, 1000),

    fetchFiltersEntities () {
      if (this.filterEntity === null) {
        this.fetchEntities()
      }else {
        axios.get(`/api/entities/filter/${this.filterEntity}`)
          .then(response => {
            this.entities = response.data.data.data
            this.totalEntities = response.data.data.total
          })
      }
    },

    save () {
      if (this.editedIndex > -1) {
        this.updateEntities()
      } else {
        this.saveEntities()
      }
      this.errors.record('');
    },

    deleteEntities () {
      axios.delete(`/api/entities/${this.editedItem.entity_id}`)
        .then(() => {
          this.fetchEntities()
          this.close()
          this.snackbar = true;
        })
    },

    editItem (item) {
      this.editedIndex = this.entities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.entities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteEntities()
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.defaultItem.parent.id = null
        this.editedIndex = -1
      })
      this.errors.record('')
      this.editedItem.parent.id = null
      Object.assign(this.$data, this.$options.data.bind(
          this.parentModify = {
            id: null,
            name: 'NINGUNO'
          }
      ))
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
<style>
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}

.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}
.theme--light.v-input {
  padding-top: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
